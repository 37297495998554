import Switch from '@/components/Switch';
import type { VNode } from 'vue';
import Button from '../Button';
import './TrackingConsentForm.css';

export default defineComponent({
  name: 'TrackingConsentForm',
  props: {
    isOnboarding: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['done'],
  setup(props, { emit }) {
    const trackingConsent = useTrackingConsent();

    const hasAnalyticsConsent = ref<boolean>(
      trackingConsent.hasAnalyticsConsent.value ?? false
    );
    const hasMarketingConsent = ref<boolean>(
      trackingConsent.hasMarketingConsent.value ?? false
    );
    const hasPreferencesConsent = ref<boolean>(
      trackingConsent.hasPreferencesConsent.value ?? false
    );

    // After onboarding, in More-menu, show custom settings by default
    const isCustomSettingsVisible = ref<boolean>(!props.isOnboarding);

    const toggleAnalyticsConsent = () => {
      hasAnalyticsConsent.value = !hasAnalyticsConsent.value;
    };
    const toggleMarketingConsent = () => {
      hasMarketingConsent.value = !hasMarketingConsent.value;
    };
    const togglePreferencesConsent = () => {
      hasPreferencesConsent.value = !hasPreferencesConsent.value;
    };

    const toggleCustomSettingsVisible = () => {
      isCustomSettingsVisible.value = !isCustomSettingsVisible.value;
    };

    const handleSubmitAcceptAll = async () => {
      hasAnalyticsConsent.value = true;
      hasPreferencesConsent.value = true;
      hasMarketingConsent.value = true;
      await saveAndFinish();
    };

    const handleSubmitAcceptCustom = async () => {
      await saveAndFinish();
    };

    const saveAndFinish = async () => {
      await trackingConsent.saveConsent({
        trackingConsentAnalytics: hasAnalyticsConsent.value,
        trackingConsentMarketing: hasMarketingConsent.value,
        trackingConsentPreferences: hasPreferencesConsent.value,
      });
      emit('done');
    };

    return {
      handleSubmitAcceptAll,
      handleSubmitAcceptCustom,
      hasAnalyticsConsent,
      hasMarketingConsent,
      hasPreferencesConsent,
      isCustomSettingsVisible,
      toggleCustomSettingsVisible,
      toggleAnalyticsConsent,
      toggleMarketingConsent,
      togglePreferencesConsent,
    };
  },
  render(): VNode {
    return (
      <div class="consent">
        <div class="flex flex-col">
          <p>{this.$t('tracking_consent_form.intro')}</p>

          <div class="mb-4 mt-6 flex flex-col items-center gap-2 flex-wrap">
            <Button
              class="button button-primary w-3/4"
              onClick={this.handleSubmitAcceptAll}
            >
              {this.$t('tracking_consent_form.accept_all')}
            </Button>
            <Button
              class="button button-stroke w-3/4"
              onClick={this.handleSubmitAcceptCustom}
            >
              {this.$t('tracking_consent_form.accept_necessary')}
            </Button>
            <Button
              class="button button-stroke w-3/4"
              onClick={this.toggleCustomSettingsVisible}
              disabled={this.isCustomSettingsVisible}
            >
              {this.$t('tracking_consent_form.custom_settings')}
            </Button>
          </div>
        </div>

        {this.isCustomSettingsVisible && (
          <form>
            <div class="flex flex-col">
              <div class="flex items-center">
                <h2>{this.$t('tracking_consent_form.necessary_heading')}</h2>
                <Switch
                  checked={true}
                  swapColor={true}
                  disabled={true}
                  align="right"
                  class="ml-auto py-4"
                />
              </div>
              <p>
                {this.$t('tracking_consent_form.notice')}&nbsp;
                <a
                  href="https://ruokaboksi.fi/rekisteriseloste/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {this.$t('tracking_consent_form.read_more')}
                </a>
                .
              </p>
            </div>

            <div class="flex flex-col">
              <div class="flex items-center">
                <h2>{this.$t('tracking_consent_form.analytics_heading')}</h2>
                <Switch
                  checked={this.hasAnalyticsConsent}
                  handleToggle={this.toggleAnalyticsConsent}
                  swapColor={true}
                  align="right"
                  class="ml-auto py-4"
                />
              </div>
              <p>{this.$t('tracking_consent_form.analytics_notice')}</p>
              <table class="responsive-table mt-2.5">
                <thead>
                  <tr>
                    <th>{this.$t('tracking_consent_form.service_heading')}</th>
                    <th>{this.$t('tracking_consent_form.www_address')}</th>
                    <th>{this.$t('tracking_consent_form.type')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      {this.$t('tracking_consent_form.sentry')}
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      sentry.io
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      {this.$t('tracking_consent_form.posthog')}
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      posthog.com
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex flex-col">
              <div class="flex items-center">
                <h2>{this.$t('tracking_consent_form.preferences_heading')}</h2>
                <Switch
                  checked={this.hasPreferencesConsent}
                  handleToggle={this.togglePreferencesConsent}
                  swapColor={true}
                  align="right"
                  class="ml-auto py-4"
                />
              </div>
              <p>{this.$t('tracking_consent_form.preferences_notice')}</p>
              <table class="responsive-table mt-2.5">
                <thead>
                  <tr>
                    <th class="pr-7">
                      {this.$t('tracking_consent_form.service_heading')}
                    </th>
                    <th class="pr-7">
                      {this.$t('tracking_consent_form.www_address')}
                    </th>
                    <th class="pr-7">
                      {this.$t('tracking_consent_form.type')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      {this.$t('tracking_consent_form.userguiding')}
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      userguiding.com
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      {this.$t('tracking_consent_form.zendesk')}
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      zendesk.com
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex flex-col">
              <div class="flex items-center">
                <h2>{this.$t('tracking_consent_form.marketing_heading')}</h2>
                <Switch
                  checked={this.hasMarketingConsent}
                  handleToggle={this.toggleMarketingConsent}
                  swapColor={true}
                  align="right"
                  class="ml-auto py-4"
                />
              </div>
              <p>{this.$t('tracking_consent_form.marketing_notice')}</p>
              <table class="responsive-table mt-2.5">
                <thead>
                  <tr>
                    <th class="pr-7">
                      {this.$t('tracking_consent_form.service_heading')}
                    </th>
                    <th class="pr-7">
                      {this.$t('tracking_consent_form.www_address')}
                    </th>
                    <th class="pr-7">
                      {this.$t('tracking_consent_form.type')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      {this.$t('tracking_consent_form.custobar')}
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      custobar.com
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      Tracklution
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      tracklution.com
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      Tiktok
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      tiktok.com
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      Meta
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      facebook.com
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      data-label={this.$t(
                        'tracking_consent_form.service_heading'
                      )}
                    >
                      Google Ads
                    </td>
                    <td
                      data-label={this.$t('tracking_consent_form.www_address')}
                    >
                      ads.google.com
                    </td>
                    <td data-label={this.$t('tracking_consent_form.type')}>
                      {this.$t('tracking_consent_form.application_settings')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Button
              class="button button-primary mt-8"
              onClick={this.handleSubmitAcceptCustom}
              disabled={!this.isCustomSettingsVisible}
            >
              {this.$t('tracking_consent_form.save_settings')}
            </Button>
          </form>
        )}
      </div>
    );
  },
});
